<template>
  <section class="dtc-main-section mt-2 mr-2">
    <header
      class="dtc-header"
      style="font-size: 20px; display: grid; place-items: center;font-family:DFKai-sb;"
    >
      <div>
        {{ (sheadData) ? sheadData.BYear : "___" }}學年度{{ (sheadData) ? sheadData.SchoolName : "_______" }} 原住民及離島地區醫事人員養成計畫公費生
      </div>
      <div>暑期課程學分費補助申請清冊</div>
    </header>
    <header class="dtc-grid-header ml-1">
      <div>序</div>
      <div>姓名</div>
      <div>籍屬</div>
      <div>系別</div>
      <div>修習科目</div>
      <div>學分</div>
      <div>成績</div>
      <div class="header-column2">
        <div class="header-column2-row1">
          <div class="header-column2-row1-word">暑期班費用</div>
        </div>
        <div class="header-column2-row2">
          <div>每學分費用</div>
          <div>小計</div>
        </div>
      </div>
      <div>實際支付費用</div>
      <div>申請補助金額</div>
      <div>簽領</div>
      <div>備註</div>
    </header>
    <div v-if="sheadData"> 
      <table class="ml-1" v-for="(item, i) in sheadData.bsc_summary" :key="i">
        <tr>
          <td rowspan="0" style="width:40px;border-left:1px solid #343a40;">{{ +i + 1}}</td>
          <td rowspan="0" style="width:120px">{{ item.list[0].Name }}</td>
          <td rowspan="0" style="width:100px">{{ modalCountryName(item.list[0].Country) }}</td>
          <td rowspan="0" style="width:150px">{{ item.list[0].MajorName }}</td>
          <td style="width:180px">{{ item.list[0].StudyClass }}</td>
          <td style="width:70px">{{ item.list[0].Hours }}</td>
          <td style="width:70px">{{ item.list[0].Fraction }}</td>
          <td style="width:110px" class="price-content">${{ $formatPrice(item.list[0].Tuition) }}</td>
          <td style="width:110px" class="price-content">${{ $formatPrice(item.list[0].Tuition * item.list[0].Hours) }}</td>
          <td style="width:110px" class="price-content">${{ $formatPrice(item.list[0].RealPay) }} </td>
          <td rowspan="0" style="width:110px" class="price-content">${{ $formatPrice(item.total) }}</td>
          <td rowspan="0" style="width:120px"></td>
          <td style="width:181px">{{ item.list[0].Remark }}</td>
        </tr>
        <tr v-for="(e,i) in item.list.slice(1)" :key="i">
          <td style="width:180px">{{ e.StudyClass }}</td>
          <td style="width:70px">{{ e.Hours }}</td>
          <td style="width:70px">{{ e.Fraction }}</td>
          <td style="width:110px" class="price-content">${{ $formatPrice(e.Tuition) }}</td>
          <td style="width:110px" class="price-content">${{ $formatPrice(e.Tuition * e.Hours) }}</td>
          <td style="width:110px" class="price-content">${{ $formatPrice(e.RealPay) }} </td>
          <td style="width:181px">{{ e.Remark }}</td>
        </tr>
      </table>
    </div>
    <footer
      class="dtc-grid-footer main-dtc-body ml-1"
      style="color: #212529; background-color: #fff;border-top:0px"
    >
      <div class="column1-content">總計申請</div>
      <div class="price-content">${{ (sheadData) ? $formatPrice(sheadData.Apply_Amt) : "N/A" }}</div>
      <div></div>
    </footer>
    <footer class="ml-5 mt-1 mb-4 grid-footer-4">
      <div>註冊組</div>
      <div>出納組</div>
      <div>財務處</div>
      <div>校長</div>
    </footer>
  </section>
</template>

<script>
import queryString from "query-string";
import { store } from "@/store/global.js";
const year = new Date().getFullYear() - 1905;
let years = new Array(8).fill().map((s, i) => year + 1 - i);

import Swal from "sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";
export default {
  name: "ShowTable",
  data() {
    return {
      seqno: -1,
      bYear: "000",
      schoolCode: "0000",
      indexTable: 0,
      tableTitle: "",
      sheadData: null,
      countryItems: [], 
      majorItems: [], 
      items: [],
      headers: [],
      variants: [
        "warning",
        "dark",
        "light",
        "warning",
        "danger",
        "info",
        "light",
        "dark",
      ],
      years,
      selectedYear: new Date().getFullYear() - 1911,
      modalType: 1,

      modalName: "",
      modalCountry: "",
      modalDepartment: "",
      modalSubject: "",
      modalCredit: "",
      modalCreditFee: 1245,
      modalScore: 58,
      modalRealFee: 89689,
      modalComment: "",
    };
  },
  components: {},
  props: [],
  computed: {
    nowTableTitle() {
      return store.nowTableTitle;
    },
    nowTab() {
      return store.nowTab;
    },
  },
  methods: {
    modalCountryName(v){ 
      if(this.countryItems.length > 0 && v){
        let rr =  this.countryItems.find(r => r.value == v);
        return rr?rr.text : v;
      }
      return '';
    },
    modalMajorName(v){ 
      if(this.majorItems.length > 0 && v){
        let rr = this.majorItems.find(r => r.value == v);
        return rr?rr.text:v; 
      }
      return '';
    },
    openModal(key) {
      this.modalType = key;
      this.$bvModal.show("editModal");
    },
    openPrintPage() {
      let url = "";
      url = `/#/doc4?schoolcode=${this.SchoolCode}&byear=${this.byear}&term=1`;
      window.open(url);
    },
    saveData(number, key, i, major) {
      const remind = () => {
        this.$bvToast.toast("輸入值請勿超過上限", {
          title: "系統資訊",
          autoHideDelay: 5000,
          variant: "danger",
        });
      };

      if (number > 5000 && key == 12) {
        this.items[i].col12 = 5000;
        remind();
      } else if (number > 18000 && key == 13) {
        this.items[i].col13 = 18000;
        remind();
      } else if (
        number > 30000 &&
        key == 14 &&
        (major == "醫學系" || major == "牙醫系")
      ) {
        this.items[i].col14 = 30000;
        remind();
      } else if (number > 15000 && key == 14) {
        this.items[i].col14 = 15000;
        remind();
      }
    },
    searchDb() {
      this.search = true;
      this.getData();
    },
    async getCountry(){
      try {
        let { Items, Count } = await window.axios.get(`api/Country`);
        if(Items){
          Items=Items.filter((r)=>{ return r.IsActive });
          Items = Items.map((s) => ({
            value: s.Cid,
            text: s.CountryName,
          }));
        }
        this.countryItems = Items;
      } catch (e) {
        this.$bvToast.toast(`${e}`, {
          title: "系統資訊",
          autoHideDelay: 5000,
          variant: "danger",
        });
      }
    },
    async getMajor(){
      try {
        let Items = await window.axios.get(`Major/Get?schoolcode=${this.SchoolCode}`);
        if(Items){
          Items = Items.map((s) => ({
            value: s.MajorId,
            text: s.MajorName,
          }));
        }
        //console.log('getMajor',this.editItem.SchoolCode,Items);
        this.majorItems = Items;
      } catch (e) {
        this.$bvToast.toast(`${e}`, {
          title: "系統資訊",
          autoHideDelay: 5000,
          variant: "danger",
        });
      }
    },
    async getData() {
      var url = `Budgeting/GetSummer2?seqno=${this.seqno}`;
      try {
        let res = await window.axios.get(url);
        this.sheadData = JSON.parse(JSON.stringify(res));
        let students = this.sheadData.budgeting_summer_class.map(x => x.Identifier)
              .filter(function(value, index, self) {
                return self.indexOf(value) === index;
              });
        var orgSC = this.sheadData.budgeting_summer_class
        this.sheadData.bsc_summary = students.map(function(e){
          var t = 0;
          var d = orgSC.filter(function(v){
            if(v.Identifier == e){
              t = t + v.RealPay;
            }
            return v.Identifier == e;
          });
          return {list: d, total: t};
        });
      } catch (e) {
        this.$bvToast.toast(`${e}`, {
          title: "系統資訊",
          autoHideDelay: 5000,
          variant: "danger",
        });
      }
    },
  },
  mounted() {
    const { seqno, schoolcode, byear, term } = queryString.parse(
      location.href.split("?")[1]
    );
    this.seqno = seqno;
    this.bYear = byear;
    this.schoolCode = schoolcode;
    this.getCountry();
    this.getMajor();
    this.getData();
  },
  watch: {},
};
</script>

<style lang="scss" scoped>
.dtc-main-section {
  padding: 12px;
  width: fit-content;
}
.dtc-grid-header {
  display: grid;
  grid-template-columns: 40px 120px 100px 150px 180px 70px 70px 220px 110px 110px 120px 180px;
  grid-auto-flow: column;
  text-align: center;
  padding-right: 0px;

  border: 1px solid #343a40;
  // border-bottom: 0px;
  margin-right: 4px;

  > div {
    border-right: 1px solid #343a40;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    font-size: 14px;
    padding: 4px 0;
    height: 68px;
    padding-top: 15px;
  }
  > div:last-child {
    border-right: none;
  }
  .header-column2 {
    padding-top: 0px;

    .header-column2-row1 {
      text-align: center;
      height: 36px;
      line-height: 36px;
    }
    .header-column2-row2 {
      display: grid;
      grid-template-columns: 110px 110px;
      > div {
        border-right: 1px solid #343a40;
        border-top: 1px solid #343a40;
        height: 32px;
        line-height: 32px;
      }
    }
  }
}
.main-dtc-body {
  &:hover {
    background-color: rgba(0, 0, 0, 0.075);
  }
  > div {
    line-height: 24px;
  }
}
.dtc-grid-main,
.dtc-grid-footer {
  display: grid;
  grid-template-columns: 40px 120px 50px 120px repeat(11, 120px) 176px;
  margin-right: 4px;
  grid-auto-flow: column;
  text-align: center;
  padding-right: 0px;
  border-left: 1px solid #343a40;
  border-right: 1px solid #343a40;

  color: #212529;
  > div {
    border-right: 1px solid #343a40;
    color: #212529;
    text-overflow: ellipsis;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    font-size: 14px;
    padding-top: 10px;
    padding-bottom: 10px;
  }
  > div:last-child {
    border-right: none;
  }
}
.dtc-grid-footer {
  grid-template-columns: 1060px 110px 300px;
  border: 1px solid #343a40;
  color: #212529;

  > div {
    border-right: 1px solid #343a40;
    margin-top: 0px;
  }
  > div:last-child {
    border-right: none;
  }
}

.my-dark {
  > div {
    // background: var(--gray);
    background: #333;
    color: white;
  }
}

::v-deep.btn-light {
  background: #7e2ca3;
  border-color: #7e2ca3;
  color: #fff;
}
.search-block {
  background: #fbfcf9;
  border: 1px solid #bdbdbd;
  border-radius: 2px 2px 0 0;
  width: 99.3%;
  margin: 0 auto 10px;
  .big-title {
    background: #0379fd;
    padding: 5px;
    color: #fff;
    border-radius: 2px 2px 0 0;
  }
  .dtc-search,
  .dtc-search1 {
    padding-left: 10px;
    display: grid;
    max-height: 40px;
    margin-bottom: 12px;
    grid-template-columns: repeat(30, max-content);
    grid-gap: 12px;
    > * {
      max-height: 44px;
    }
  }
}
td {
  // border: 1px solid #343a40;
  border-right: 0.5px solid #343a40;
  border-bottom: 0.5px solid #343a40;
  text-align: center;
}
.table-content {
  padding: 3px;
}
.input-group-text {
  width: 122px;
  background: #0379fd;
  color: #fff;
}
.edit-content {
  text-align: end;
}
.price-content {
  text-align: right;
  padding-right: 6.5px;
}
.grid-footer-4 {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  padding-left: 10px;
  font-size: 24px;
}
</style>
